'use client'
import { Banner as BannerType } from '@/app/util/banner'
import Banner from '@/components/banner'
import Grid from '@/components/grid/grid'
import VideoGrid from '@/components/grid/video-grid'
import _TV from '@/components/homepage/artist-tv'
import TrendingIcon from '@/components/search-bar/search-dropdown/icons/trending'
import { SectionHeader as _SectionHeader } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { publicRuntimeConfig } from '@/app/util/env'
import { GiphyFetch, MediaType } from '@giphy/js-fetch-api'
import { IChannel, IGif } from '@giphy/js-types'
import { setGADataLayer } from 'analytics'
import { isEmpty } from 'lodash'
import { useContext, useState } from 'react'
import styled, { css } from 'styled-components'
import _SegmentedControl from 'ui/src/components/controls/segmented-control'
import { desktop } from 'ui/src/css'
import { getArtists } from '@/app/util/channel-api'

const SegmentedControl = styled(_SegmentedControl)`
    margin: 1px 0 0;
    ${desktop(css`
        width: 400px;
        margin: 14px 0;
    `)}
`

const SectionHeader = styled(_SectionHeader)``

const GridHeader = styled.div`
    ${SectionHeader} {
        display: none;
    }

    ${desktop(css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        ${SectionHeader} {
            display: inline-block;
        }
    `)}
`

const HeaderContainer = styled.div`
    ${desktop(css`
        display: flex;
        flex-direction: column-reverse;
    `)}
`

const TV = styled(_TV)`
    display: none;
    ${desktop(css`
        display: block;
        position: absolute;
        top: 0;
        right: 1px;
    `)}
`

const PageContent = styled.div`
    margin-top: 6px;
    position: relative;
    ${desktop(css`
        margin-top: 0;
    `)}
`

type Props = {
    banner?: BannerType
    trendingGifs?: IGif[]
    artists?: IChannel[]
}

const fetchConfig = {
    clientMobile: 8,
    clientDesktop: 12,
} as const

const clientGiphyFetch = new GiphyFetch(publicRuntimeConfig.mobileApiKey)

const fetchArtists = async (offset: number) => getArtists({ offset, limit: 3 }, document.cookie)
const getTrackingUrl = (page: number) => `/homepage/page/${page}`

// This page can be seen in production at /test
const HomeContents = ({ banner, trendingGifs = [] }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const contentTypes = ['gifs', 'stickers', 'clips'] as const
    const [type, setType] = useState<(typeof contentTypes)[number]>('gifs')

    const gradient = type === 'gifs' ? 'purple-indigo' : type === 'stickers' ? 'blue-green' : 'indigo-blue'

    const trending = (type?: MediaType) => async (offset: number) =>
        clientGiphyFetch.trending({
            offset,
            type,
            limit: deviceType === 'desktop' ? fetchConfig.clientDesktop : fetchConfig.clientMobile,
        })

    const gridFetch = type === 'gifs' ? trending() : type === 'stickers' ? trending('stickers') : trending('videos')
    return (
        <>
            <HeaderContainer>
                <GridHeader>
                    <SectionHeader Icon={<TrendingIcon size={{ width: 22, height: 24 }} />} label="Trending" />
                    <SegmentedControl
                        defaultIndex={0}
                        size="medium"
                        options={['GIFs', 'Stickers', 'Clips']}
                        gradient={gradient}
                        onChange={(index: number) => {
                            setType(contentTypes[index] as (typeof contentTypes)[number])
                            setGADataLayer({
                                event: 'page_view',
                                options: {
                                    pageType: 'home',
                                    pageCategory: `homepage_${contentTypes[index]}`,
                                },
                            })
                        }}
                    />
                </GridHeader>
                {!isEmpty(banner) && <Banner banner={banner} />}
            </HeaderContainer>
            <PageContent>
                {type === 'clips' ? (
                    <VideoGrid fetchGifs={gridFetch} gaTrackingUrl={getTrackingUrl} />
                ) : (
                    <Grid
                        key={type}
                        initialGifs={type === 'gifs' ? trendingGifs : undefined}
                        fetchGifs={gridFetch}
                        columnOffsets={type === 'gifs' ? [0, 0, 350, 350] : undefined}
                        gaTrackingUrl={getTrackingUrl}
                    />
                )}
                {type === 'gifs' && <TV width={512} height={342} fetchGifs={fetchArtists} />}
            </PageContent>
        </>
    )
}

export default HomeContents
