type Props = {
    size?: { width: number; height: number }
}

const TrendingIcon = ({ size = { width: 18, height: 18 } }: Props) => (
    <svg width={size.width} height={size.height} viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="trending" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <rect
                    id="Rectangle"
                    stroke="#979797"
                    fill="#D8D8D8"
                    opacity="0"
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                />
                <path
                    d="M12.6093329,3.12057664 L15.156896,3.12057664 L9.64199318,9.04253019 L6.88133868,6.8175119 C6.7544587,6.67603813 6.56616874,6.60087259 6.38404017,6.61897279 C6.2490402,6.63288422 6.11891631,6.69661171 6.02063992,6.79697337 C2.21226835,10.5943119 0.308082561,12.4929812 0.308082561,12.4929812 C0.308082561,12.4929812 0.527106106,12.8074292 0.710953088,13.0215425 C0.833517743,13.1642848 0.975497751,13.3098497 1.13689311,13.4582373 L6.47329888,8.13191205 L9.16381134,10.2953038 C9.40800276,10.5710787 9.68933701,10.7021044 10.019278,10.4570223 L16.0239805,4.04474473 C16.0239805,5.87956383 16.0239805,6.79697337 16.0239805,6.79697337 C16.0239805,6.79697337 16.4320205,6.79697337 17.2481004,6.79697337 L17.2481004,1.80604505 C14.1555887,1.80604505 12.6093329,1.80604505 12.6093329,1.80604505 C12.6093329,1.80604505 12.6093329,2.24422225 12.6093329,3.12057664 Z"
                    id="Shape"
                    stroke="#00CCFF"
                    strokeWidth="0.4"
                    fill="#00CCFF"
                    fillRule="nonzero"
                    transform="translate(8.778091, 7.632141) rotate(-2.000000) translate(-8.778091, -7.632141) "
                />
            </g>
        </g>
    </svg>
)

export default TrendingIcon
